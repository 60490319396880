import AccountsPage from '../components/accounts/accounts-page';
import Contacts from '../components/contacts';
import Bills from '../components/invoices';
import Sites from '../components/sites';
import Transactions from '../components/transactions';
import { isUserSassOperator } from '../helpers/utils';
import AutomationDetails from '../pages/automation-details';
import AutomationLogDetail from '../pages/automation-log-detail';
import AutomationLogs from '../pages/automation-logs';
import Automations from '../pages/automations';
import Integrations from '../pages/integrations';
import Settings from '../pages/settings';
import Security from '../pages/settings/security';
import Users from '../pages/settings/security/users';

export const ROUTE_PATHS = [
  {
    id: 'ROUTE_SITES_INDEX',
    path: '/sites',
    Component: Sites,
    permission: ['SITES_VIEW', 'SITES_MANAGE'],
  },
  {
    id: 'ROUTE_SITES',
    path: '/sites/*',
    Component: Sites,
    permission: ['SITES_VIEW', 'SITES_MANAGE'],
  },
  {
    id: 'ROUTE_ACCOUNTS',
    path: '/accounts',
    Component: AccountsPage,
    permission: ['ACCOUNTS_VIEW'],
    otherPermissionFunc: () => isUserSassOperator(),
    params: {
      account_type: 'ACCOUNTS',
      add_btn_label: 'Add new account',
      view_permission: 'ACCOUNTS_VIEW',
      manage_permission: 'ACCOUNTS_MANAGE',
    },
  },
  {
    id: 'ROUTE_CUSTOMERS_INDEX',
    path: '/customers',
    Component: Contacts,
    permission: ['CONTACTS_VIEW'],
  },
  {
    id: 'ROUTE_BILLS',
    path: '/bills',
    Component: Bills,
    permission: ['INVOICES_VIEW'],
    // permission: ['BILLS_VIEW'],
  },
  {
    id: 'ROUTE_TRANSACTIONS',
    path: '/transactions',
    Component: Transactions,
    permission: ['TRANSACTIONS_VIEW'],
  },
  {
    id: 'ROUTE_INTEGRATIONS',
    path: '/platforms/integrations/*',
    Component: Integrations,
    permission: ['INTEGRATIONS_VIEW', 'INTEGRATIONS_MANAGE'],
  },
  {
    id: 'ROUTE_AUTOMATIONS',
    path: '/platforms/automations',
    Component: Automations,
    permission: ['AUTOMATIONS_VIEW', 'AUTOMATIONS_MANAGE'],
  },
  {
    id: 'ROUTE_AUTOMATION_LOGS',
    path: '/platforms/automation-logs',
    Component: AutomationLogs,
    permission: ['AUTOMATION_LOGS_VIEW', 'AUTOMATION_LOGS_MANAGE'],
  },
  {
    id: 'ROUTE_SETTINGS',
    path: '/settings',
    Component: Settings,
    permission: ['USERS_VIEW'],
  },
  {
    id: 'ROUTE_SECURITY',
    path: '/settings/security',
    Component: Security,
  },

  {
    id: 'ROUTE_USERS',
    path: '/settings/security/users/*',
    Component: Users,
  },
  {
    id: 'ROUTE_AUTOMATION_LOG_DETAILS',
    path: '/platforms/automation-logs/:id',
    Component: AutomationLogDetail,
    permission: ['AUTOMATION_LOGS_VIEW', 'AUTOMATION_LOGS_MANAGE'],
  },
  {
    id: 'ROUTE_AUTOMATION_DETAILS',
    path: '/platforms/automations/:id/*',
    Component: AutomationDetails,
    permission: ['AUTOMATIONS_VIEW', 'AUTOMATIONS_MANAGE'],
  },
];
