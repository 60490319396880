import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import useDebounce from '../../helpers/useDebounceHook';
import PageHeader from '../page-header';
import BillsList from './bills-list';

const Bills = () => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const debouncedSearch = useDebounce(searchText, 500);

  return (
    <Routes>
      <Route
        index
        element={
          <BillsWrapper className="flex-column flex-1">
            <PageHeader
              headerTitle={t('BILLS')}
              showSearch
              // showSort
              // showFilter
              onSortClick={() => {}}
              searchText={searchText}
              onSearchChange={setSearchText}
            />
            <BillsList debouncedSearch={debouncedSearch} />
          </BillsWrapper>
        }
      />
    </Routes>
  );
};

export const BillsWrapper = styled.div`
  max-height: 100vh;
  overflow: hidden;
`;

export default Bills;
