import { useContext } from 'react';
import { OrganisationContext } from '../context/organisationContext';

export const useFilteredMenuList = ({ menuList = [] }) => {
  const { hasPermission } = useContext(OrganisationContext);

  return menuList.filter(({ permission, otherPermissionFunc }) => {
    const hasPermissionForMenu = permission ? hasPermission(permission) : true;
    const otherPermissionForMenu = otherPermissionFunc ? otherPermissionFunc() : true;
    return hasPermissionForMenu && otherPermissionForMenu;
  });
};
