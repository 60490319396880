import { motion } from 'framer-motion';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as HorizontalDotsIcon } from '../../assets/icons/HorizontalDots.svg';
import { getFormattedDateV2, getFormattedNumber } from '../../helpers/utils';
import { useFilteredMenuList } from '../../hooks/useFilteredMenuList';
import { cn, getContactName } from '../../lib/utils';
import FlexBox from '../common/flex-box/flex';
import Label from '../common/label';
import MenuV2 from '../common/menu/menu-v2';
import Status from '../common/status';

const BillListItem = ({ bill = {}, showBorderTop = false }) => {
  const { t } = useTranslation();

  const {
    id: billId,
    account,
    start_date,
    end_date,
    due_date,
    issued_date,
    status,
    code,
    total_amount,
    integration,
  } = bill || {};
  const { account_number, contact } = account || {};
  const { phone, email } = contact || {};
  const { id: integrationId, name: integrationName, icon: integrationIcon } = integration || {};

  const contactName = getContactName(contact);
  const billingPeriodStart = getFormattedDateV2({ dateInUnix: start_date, format: 'Do of MMMM' });
  const billingPeriodEnd = getFormattedDateV2({ dateInUnix: end_date, format: 'Do of MMMM' });

  return (
    <motion.div
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0, transition: { duration: 0.9 } }}
      key={billId}
      className={cn(
        'px-6 py-4 min-h-[72px] grid grid-cols-bills-list-grid gap-x-4 cursor hover:bg-neutral-50',
        showBorderTop && 'border-top',
      )}>
      <FlexBox className="items-start">
        <Label className="inter-400-text">{code}</Label>
      </FlexBox>
      <FlexBox className="flex-col items-start gap-y-1 overflow-hidden">
        <Label className="inter-400-text">{contactName}</Label>
        <Label className="inter-400-text natural-500-text font-12">Account number: {account_number}</Label>
        <Label className="inter-400-text natural-500-text font-12">{phone || t('NO_TYPE', { type: 'phone' })}</Label>
        <Label className="inter-400-text natural-500-text font-12">{email || t('NO_TYPE', { type: 'email' })}</Label>
      </FlexBox>
      <FlexBox className="items-start">
        <Label className="inter-400-text">{`${billingPeriodStart} - ${billingPeriodEnd}`}</Label>
      </FlexBox>
      <FlexBox className="items-start">
        <Label className="inter-400-text">{getFormattedDateV2({ dateInUnix: issued_date }) || '-'}</Label>
      </FlexBox>
      <FlexBox className="items-start">
        <Label className="inter-400-text">{getFormattedDateV2({ dateInUnix: due_date }) || '-'}</Label>
      </FlexBox>
      <FlexBox className="items-start">
        <Label className="inter-400-text">{getFormattedNumber(total_amount)}</Label>
      </FlexBox>
      <FlexBox className="items-start gap-x-3">
        <Status status={status} />
        {integrationId && integrationIcon && (
          <FlexBox className="flex items-center gap-x-2">
            <img src={integrationIcon} alt={integrationName} className="w-12 h-6" />
          </FlexBox>
        )}
      </FlexBox>
      <MenuV2
        menuList={useFilteredMenuList({
          menuList: [
            {
              name: 'Add to Xero',
              otherPermissionFunc: () => !integrationId,
              onClick: () => console.log('Add to xero'),
            },
          ],
        })}
        Icon={HorizontalDotsIcon}
        menuWrapperClassname="flex"
        menuClassName="property-menu"
        iconClassname="my-4"
      />
    </motion.div>
  );
};

export default BillListItem;
