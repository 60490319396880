import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as NoDocumentIcon } from '../../assets/icons/empty-document.svg';
import { useBills } from '../../hooks/useBills';
import { useError } from '../../hooks/useError';
import InfiniteScrollV2 from '../common/infinite-scroll-v2';
import NoData from '../common/no-data';
import SkeletonTransition from '../common/skeleton-transition';
import TableHeader from '../common/table-header';
import BillListItem from './bill-list-item';

const billsTableHeaders = [
  { name: 'NUMBER', key: 'NUMBER' },
  { name: 'ACCOUNT', key: 'ACCOUNT' },
  { name: 'BILL PERIOD', key: 'BILL_PERIOD' },
  { name: 'ISSUED DATE', key: 'ISSUED_DATE' },
  { name: 'DUE DATE', key: 'DUE_DATE' },
  { name: 'AMOUNT', key: 'AMOUNT' },
  { name: 'STATUS', key: 'STATUS' },
  { name: '', key: 'MENU' },
];

export const defaultData = [
  {
    id: '1',
    start_date: moment().subtract(1, 'months').unix(),
    end_date: moment().unix(),
    code: '123',
    issued_date: moment().unix(),
    due_date: moment().add(1, 'months').unix(),
    total_amount: 900,
    status: 'PAID',
    integration: {
      id: '22',
      name: 'Priyank',
      icon: 'https://example.com/icon.png',
    },
    account: {
      id: '001',
      account_number: '0001',
      contact: {
        id: '9',
        forename: 'Priyank',
        surname: 'Kachhela',
        company_name: 'Priyank LTD',
        email: 'example.com',
        phone: '123321321',
      },
    },
    media: {
      id: '',
      url: '',
    },
  },
];

const BillsList = ({ debouncedSearch, orderBy, sortBy }) => {
  const { t } = useTranslation();

  const {
    data: billsList,
    isLoading,
    hasNextPage,
    fetchNextPage,
    error,
  } = useBills({ params: { search: debouncedSearch, orderBy, sortBy } });

  useError({ error, default_message: t('ERROR_WHILE_FETCHING_BILLS') });

  const isDataEmpty = !billsList || (billsList && billsList.length === 0);

  return (
    <div className="flex flex-col flex-1 rounded-md mt-6 mx-6 overflow-hidden">
      <SkeletonTransition
        loading={isLoading}
        loaderClassName="flex items-center justify-center flex-1 mb-6"
        height={'100%'}
        containerClassName="leading-none h-full w-full">
        {isDataEmpty && (
          <div className="flex flex-col flex-1 mb-6 items-center justify-center">
            <NoData
              title={debouncedSearch ? t('NO_RESULTS_FOUND') : t('NO_BILLS')}
              description={debouncedSearch ? t('NO_RESULTS_DESCRIPTION') : t('NO_BILLS_HERE')}
              className="search-terms"
              EmptyIcon={NoDocumentIcon}
              iconClassName="mb-6 relative"
            />
          </div>
        )}
        {!isDataEmpty && (
          <div className="w-full card radius-1_5 border overflow-scroll">
            <div className="flex-column w-fit-content min-w-full h-full">
              <TableHeader
                headers={billsTableHeaders}
                headerClassName="px-6 bg-natural-50 min-w-full w-fit h-[52px] grid grid-cols-bills-list-grid gap-x-4"
              />
              <InfiniteScrollV2
                hasMore={hasNextPage}
                fetchMoreData={fetchNextPage}
                infiniteScrollClassName="custom-scrollbar thin-scrollbar">
                {(billsList || []).map((bill, index) => (
                  <BillListItem bill={bill} key={bill.id} showBorderTop={index !== 0} />
                ))}
              </InfiniteScrollV2>
            </div>
          </div>
        )}
      </SkeletonTransition>
    </div>
  );
};

export default BillsList;
