import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import { getBills } from '../apis/bills';

export const useBills = ({ params, hasNextPage, ...rest }) => {
  return useInfiniteQuery({
    initialPageParam: 0,
    queryKey: ['bills', { ...params }],
    select: data => {
      return data?.pages?.reduce((acc, page) => {
        return [...acc, ...page.content];
      }, []);
    },
    placeholderData: keepPreviousData,
    queryFn: ({ pageParam = 0 }) => getBills({ params: { ...params, page: pageParam }, ...rest }),
    getNextPageParam: lastPage => {
      const { last, number } = lastPage;
      return last ? undefined : number + 1;
    },
  });
};
