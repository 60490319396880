import React from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';
import { getFormattedDateV2, getFormattedNumber } from '../../../../helpers/utils';
import { useBills } from '../../../../hooks/useBills';
import { useError } from '../../../../hooks/useError';
import { useFilteredMenuList } from '../../../../hooks/useFilteredMenuList';
import { cn } from '../../../../lib/utils';
import FlexBox from '../../../common/flex-box/flex';
import InfiniteScrollV2 from '../../../common/infinite-scroll-v2';
import Label from '../../../common/label';
import MenuV2 from '../../../common/menu/menu-v2';
import Status from '../../../common/status';
import TableHeader from '../../../common/table-header';

const billHeaders = [
  { name: 'NUMBER', key: 'NUMBER' },
  { name: 'BILL PERIOD', key: 'BILL_PERIOD' },
  { name: 'ISSUED DATE', key: 'ISSUED_DATE' },
  { name: 'DUE DATE', key: 'DUE_DATE' },
  { name: 'AMOUNT', key: 'AMOUNT' },
  { name: 'STATUS', key: 'STATUS' },
  { name: '', key: 'MENU' },
];

const BillContent = ({ bill, showBorderBottom = false }) => {
  const { code, start_date, end_date, issued_date, due_date, status, total_amount, integration } = bill || {};
  const { id: integrationId, name: integrationName, icon: integrationIcon } = integration || {};

  const billingPeriodStart = getFormattedDateV2({ dateInUnix: start_date, format: 'Do of MMMM' });
  const billingPeriodEnd = getFormattedDateV2({ dateInUnix: end_date, format: 'Do of MMMM' });

  return (
    <div
      className={cn(
        'px-4 py-4 grid grid-cols-account-bills-grid gap-x-4 cursor hover:bg-neutral-50',
        showBorderBottom && 'border-bottom',
      )}>
      <Label className="flex items-start inter-400-text one-line">{code}</Label>
      <Label className="flex items-start inter-400-text">{`${billingPeriodStart} - ${billingPeriodEnd}`}</Label>
      <Label className="flex items-start inter-400-text">
        {getFormattedDateV2({ dateInUnix: issued_date }) || '-'}
      </Label>
      <Label className="flex items-start inter-400-text">{getFormattedDateV2({ dateInUnix: due_date }) || '-'}</Label>
      <Label className="flex items-start inter-400-text">{getFormattedNumber(total_amount)}</Label>
      <Label className="flex items-start inter-400-text gap-x-3">
        <Status status={status} />
        {integrationId && integrationIcon && (
          <FlexBox className="flex items-center gap-x-2">
            <img src={integrationIcon} alt={integrationName} className="w-12 h-6" />
          </FlexBox>
        )}
      </Label>
      <div className="flex items-center justify-end">
        <MenuV2
          iconClassname="rotate-90"
          menuList={useFilteredMenuList({
            menuList: [
              {
                name: 'Add to Xero',
                otherPermissionFunc: () => !integrationId,
                onClick: () => console.log('Add to xero'),
              },
            ],
          })}
        />
      </div>
    </div>
  );
};

const AccountBills = () => {
  const { t } = useTranslation();
  const { account_id } = useParams();

  const {
    data: billsList,
    isLoading,
    hasNextPage,
    fetchNextPage,
    error,
  } = useBills({
    params: { account_id, page: 0 },
  });

  useError({ error, default_message: t('ERROR_WHILE_FETCHING_BILLS') });

  const isDataEmpty = !billsList || (billsList && billsList.length === 0);

  return isLoading ? (
    <Skeleton containerClassName="h-full tracking-[1px]" height={'100%'} />
  ) : (
    <div className="flex-col flex border rounded-xl overflow-x-auto">
      <TableHeader
        headers={billHeaders}
        headerClassName="px-4 bg-natural-50 min-w-full w-fit h-[52px] grid grid-cols-account-bills-grid gap-x-4"
      />
      {isDataEmpty && (
        <div className="flex items-center justify-center h-14 inter-400-text">{t('NO_TYPE', { type: 'bills' })}</div>
      )}
      {!isDataEmpty && (
        <InfiniteScrollV2
          infiniteScrollClassName="min-w-full w-fit"
          hasMore={hasNextPage}
          fetchMoreData={fetchNextPage}>
          {billsList.map((bill, index) => (
            <BillContent key={bill.id} bill={bill} showBorderTop={index !== 0} />
          ))}
        </InfiniteScrollV2>
      )}
    </div>
  );
};

export default AccountBills;
